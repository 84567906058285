#head-nav {
  width: 100%;
  position: fixed;
  z-index: 100;
}

#inner-nav {
  display: flex;
  justify-content: space-between;
}

#nav > li {
  color: var(--primary);
  font-family: "HansonBold", sans-serif;
  font-size: 20px;
  text-transform: uppercase;
  padding-left: 20px;
  padding-bottom: 10px;
}

#nav {
  z-index: 400;
  display: flex;
  flex-wrap: wrap;
}

.nav-visible {
  top: 2.5%;
  transition: top 0.6s ease-in-out;
}

.nav-hidden {
  top: -100px;
  transition: top 0.3s ease-in-out;
}

#nav > li > a {
  color: var(--primary);
}

#nav-logo {
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  fill: var(--primary);
  stroke: var(--primary);
  z-index: 100;
  fill-opacity: 0;
  stroke-dashoffset: 768px;
  stroke-dasharray: 768px;
  animation-name: reversedStrokeAnim;
  animation-duration: 1s;
  animation-delay: 0;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

#nav-logo:hover {
  stroke: var(--primary);
  stroke-dashoffset: 200px;
  stroke-dasharray: 768px;
  animation-name: strokeAnim;
  animation-duration: 1s;
  fill-opacity: 1;
  animation-delay: 0;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes strokeAnim {
  50% {
    fill-opacity: 0;
  }
  to {
    fill-opacity: 0;
    stroke-dashoffset: 768px;
  }
}

@keyframes reversedStrokeAnim {
  50% {
    stroke-dashoffset: 200px;
  }
  to {
    fill-opacity: 1;
    stroke-opacity: 0;
    stroke-dashoffset: 200px;
  }
}
@media only screen and (max-width: 500px) {
  #nav-logo {
    width: 40px;
    height: 40px;
  }
}
