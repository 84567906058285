@font-face {
  font-family: "HansonBold";
  src: url("../public/HansonBold.woff2") format("woff2"),
    url("../public/HansonBold.woff") format("woff"),
    url("../public/HansonBold.ttf") format("truetype");
  font-style: normal;
}

* {
  font-family: "HansonBold", sans-serif;
  position: relative;
  font-weight: normal;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

video:focus {
  outline: 0;
}

:root {
  --primary: #ff0044;
  --dark: #1d1d1b;
  --super-dark: #272725;
}
body {
  background-color: var(--super-dark);
}

input,
textarea {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: none;
}

section {
  padding: 50px 0;
  background-color: var(--super-dark);
}

textarea {
  min-height: 250px;
}
form {
  margin: 50px 0;
}
button {
  width: 100%;
  padding: 12px 20px;
  color: var(--primary);
  text-transform: uppercase;
  background-color: transparent;
  border: var(--primary) 2px solid;
}

button:hover {
  background-color: var(--primary);
  color: white;
}

label {
  text-transform: uppercase;
  color: white;
}

input:focus,
textarea:focus,
button:focus {
  outline-color: var(--primary);
  outline-width: 2px;
  outline-style: solid;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  color: var(--primary);
}

svg {
  width: 60px;
  height: 60px;
  fill: var(--primary);
}

img {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
  transition: all 0.5s ease;
  image-rendering: -webkit-optimize-contrast;
}

img:hover {
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  filter: grayscale(0%);
  image-rendering: -webkit-optimize-contrast;
}

h1 {
  font-size: 120px;
  line-height: 0.8;
  color: var(--primary);
}

h2 {
  font-size: 70px;
}
h3 {
  font-size: 50px;
  max-width: 800px;
  color: var(--primary);
}
h4 {
  padding-top: 50px;
  font-size: 30px;
  color: transparent;
  letter-spacing: 1px;
  text-transform: uppercase;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: var(--primary);
}
h5 {
  padding-top: 10px;
  font-size: 20px;
  color: var(--primary);
}
.container {
  max-width: 1440px;
  justify-content: center;
  margin: 0 auto;
  padding: 0 2.5%;
  position: relative;
}

.primary {
  color: var(--primary);
}
p {
  margin-bottom: 20px;
}
.screen-height {
  padding-top: 10vh;
  height: 90vh;
}

.flexbox {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.bottom-aligned {
  align-items: flex-end;
}
.right-aligned {
  margin-left: auto;
}

.super-head {
  z-index: 100;
  margin-bottom: 50px;
  vertical-align: baseline;
  animation-name: fadeInScale;
  animation-duration: 0.4s;
  animation-delay: 0ms;
  animation-timing-function: ease-in;
}

.copy {
  z-index: 20;
  font-size: 20px;
  font-stretch: normal;
  line-height: 1.6;
  max-width: 600px;
  color: white;
  letter-spacing: 0.05em;
}

.outline {
  color: transparent;
  font-weight: normal;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: var(--primary);
}

.line {
  border-left: 1px solid var(--primary);
  height: 50px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.centered {
  padding: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

a:hover {
  color: var(--primary);
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--primary);
}
.fadeIn {
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 0.4s;
  animation-delay: 0.2s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}

@keyframes fadeInScale {
  from {
    opacity: 0;
    transform: scale3d(1.3, 1.3, 1.3);
  }
  to {
    opacity: 1;
    transform: scaleX(1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 1000px) {
  h1 {
    font-size: 60px;
  }
  h2 {
    font-size: 50px;
  }
  h3 {
    font-size: 40px;
  }
  .screen_height {
    height: 90vh;
  }
}

@media only screen and (max-width: 500px) {
  h1 {
    font-size: 50px;
  }
  h2 {
    font-size: 40px;
  }
  h3 {
    font-size: 25px;
  }
  .line {
    display: none;
  }
  .copy {
    font-size: 16px;
  }
}
